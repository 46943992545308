import React from "react";

interface MainProps {}

const Main: React.FC<MainProps> = ({}) => {
  return (
    <main
      className="h-screen w-screen"
      style={{
        backgroundImage: `url(${require("../mylou-records.jpg").default})`,
        backgroundPosition: "50%",
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Card />
    </main>
  );
};
export default Main;

const Card = () => {
  return (
    <div
      style={{ backgroundColor: "rgba(244,243,243, 0.9)", display: "none" }}
      className="w-screen max-w-2xl text-xs text-left absolute transform top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 lg:text-sm 2xl:top-1/2 2xl:left-1/4 2xl:-translate-x-1/2 2xl:-translate-y-1/2 2xl:text-lg"
    >
      <img
        className="hidden lg:h-20 lg:block"
        src="/images/logo-original.png"
        alt="PlaylistKING logo"
      />
      <div className="ml-6 mr-6 mb-6 mt-6 rounded">
        <p className="gray-custom-text">
          PlaylistKING&reg; is a groundbreaking b2b music playlist solution that
          combines the human expertise of record producers with the horsepower
          of cloud computing.
        </p>
        <br></br>
        <p className="gray-custom-text">
          Our global team evaluates tracks based on our own critical criteria,
          creating the highest quality metadata.
        </p>
        <br></br>
        <p className="gray-custom-text">
          Our technology solutions leverage this proprietary dataset to deliver
          impressive playlists and recommendations that can be refreshed
          automatically.
        </p>
      </div>
    </div>
  );
};
